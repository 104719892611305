import { Injectable } from '@angular/core';
import { Subject, Observable, Subscription} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private pricingData;
  private nearbyPlaces;
  private nearbyPlacesSubject = new Subject();
  private googleAddress;
  private googleAddressSubject = new Subject();
  private firstSearch;
  private firstSearchSubject = new Subject();
  private bestMatch;
  private bestMatchSubject = new Subject();
  private otherResults;
  private otherResultsSubject = new Subject();
  private showTimeoutModal = new Subject();
  private showChangelogModal = new Subject();
  private quoteIdSubject = new Subject();
  selectedSpeeds = [];
  private selectedAttSpeeds = [];

  searchResults = null;
  fiberStatus = false;
  selectedServicesArr = [];
  customerName = '';
    
  constructor() {}
  
  public quoteIdListener(): Observable<any> {
    return this.quoteIdSubject.asObservable();
  }

  public emitQuoteId(id) {
    this.quoteIdSubject.next(id);
  }

  public changelogModalListener(): Observable<any> {
    return this.showChangelogModal.asObservable();
  }

  public emitShowChangelogModal() {
    this.showChangelogModal.next();
  }
  
  public timeoutModalListener(): Observable<any> {
    return this.showTimeoutModal.asObservable();
  }

  public emitShowTimeoutModal() {
    this.showTimeoutModal.next();
  }

  public updateNearbyPlaces(): Observable<any> {
    return this.nearbyPlacesSubject.asObservable();
  }

  public updateGoogleAddress(): Observable<any> {
    return this.googleAddressSubject.asObservable();
  }

  public firstSearchListener(): Observable<any> {
    return this.firstSearchSubject.asObservable();
  }

  public bestMatchListener(): Observable<any> {
    return this.bestMatchSubject.asObservable();
  }

  public otherResultsListener(): Observable<any> {
    return this.otherResultsSubject.asObservable();
  }

  public setOtherResults(results) {
    this.otherResults = results;
    this.otherResultsSubject.next(this.otherResults);
  }

  public getOtherResults() {
    return this.otherResults;
  }

  public setBestMatch(bestMatch) {
    this.bestMatch = bestMatch;
    this.bestMatchSubject.next(this.bestMatch);
  }
  public getBestMatch() {
    return this.bestMatch;
  }


  public makeFirstSearch(state) {
    this.firstSearch = state;
    this.firstSearchSubject.next(this.firstSearch);
  }

  public getSearchState() {
    return this.firstSearch;
  }

  public setPricingData(addressEntered, serviceAddress, provider, serviceType, jediPricing) {
    this.pricingData = {addressEntered, serviceAddress, provider, serviceType, jediPricing};
  }

  public getPricingData(){
    return this.pricingData;
  }

  public setLastNearbyPlaces(places) {
    this.nearbyPlaces = places;
    this.nearbyPlacesSubject.next(this.nearbyPlaces);
  }

  public getLastNearbyPlaces() {
    return this.nearbyPlaces;
  }

  public setGoogleAddress(address) {
    this.googleAddress = address;
    this.googleAddressSubject.next(this.googleAddress);
  }

  public getGoogleAddress() {
    return this.googleAddress;
  }

  public extractAddress2(address, address1Flag) {
    let index;
    let result = address1Flag ? address : '';
    let tmpAddress = address.toLowerCase();
    let phrases1 = [' street', ' st', ' st.', ' road', ' rd', ' rd.', ' boulevard ', ' blvd ', ' blvd.', ' place', ' avenue', ' ave', ' ave.', ' trail', ' trl', ' trl.', ' drive', ' dr', ' dr.'];
    let phrases2 = [' unit', ' building', ' bldg', ' bdlg', ' suite', ' ste', ' ste.', '#'];
    for (let i = 0; i < phrases1.length; i++) {
      let pattern = new RegExp(`${phrases1[i]}[^\.|,|\s]+`, 'g');
      if (tmpAddress.indexOf(phrases1[i]) > -1 && !tmpAddress.includes('state') && !tmpAddress.includes('stone') && !tmpAddress.match(pattern)) {
        index = tmpAddress.indexOf(phrases1[i]);
        if (address1Flag) {
          result = address.substring(0, index + phrases1[i].length);
        } else {
          result = address.substring(index + phrases1[i].length + 1, address.length);
        }
        break;
      }
    }
    if (!index) {
      for (let i = 0; i < phrases2.length; i++) {
        let pattern = new RegExp(`${phrases1[i]}[^\.|,|\s]+`, 'g');
        if (tmpAddress.indexOf(phrases2[i]) > 0 && !tmpAddress.match(pattern)) {
          index = tmpAddress.indexOf(phrases2[i]);
          if (address1Flag) {
            result = address.substring(0, index - 1);
          } else {
            result = address.substring(index, address.length);
          }
          break;
        }
      }
    }
    return result;
  }

  //Comcast, Coax
  public setSelectedSpeeds(serviceProvider, selectedSpeeds, selectedTerm) {
    let addNew = true;
    this.selectedSpeeds.forEach((element, index) => {
      if (element.serviceProvider === serviceProvider) {
        if (selectedSpeeds.length) {
          element.selectedSpeeds = selectedSpeeds;
          element.selectedTerm =  selectedTerm;
        } else {
          this.selectedSpeeds.splice(index, 1);
        }
        addNew = false;
      }
    });

    if (addNew && selectedSpeeds.length) {
      this.selectedSpeeds.push({serviceProvider: serviceProvider, selectedSpeeds: selectedSpeeds, selectedTerm: selectedTerm});
    }
    console.log(this.selectedSpeeds);
  }

  public setAttSelectedSpeeds(serviceProvider, selectedAttSpeeds, selectedTerm) {
    let addNew = true;
    this.selectedAttSpeeds.forEach((element, index) => {
      if (element.serviceProvider === serviceProvider) {
        if (selectedAttSpeeds.length) {
          element.selectedSpeeds = selectedAttSpeeds;
          element.selectedTerm =  selectedTerm;
        } else {
          this.selectedAttSpeeds.splice(index, 1);
        }
        addNew = false;
      }
    });

    if (addNew && selectedAttSpeeds.length) {
      this.selectedAttSpeeds.push({serviceProvider: serviceProvider, selectedSpeeds: selectedAttSpeeds, selectedTerm: selectedTerm});
    }
  }

  public getAttSelectedSpeeds() {
    return this.selectedAttSpeeds;
  }

  public getSelectedSpeeds() {
    return this.selectedSpeeds;
  }
}