import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { DataService } from './../services/data.service';
import { retry, catchError, switchMap } from 'rxjs/operators';
import { StorageService } from './storage.service';
import { NotificationService } from './notification.service';


@Injectable({
  providedIn: 'root'
})
export class ApiService {
  // The API url needs to be changed based on the environment.
  // public apiUrl = 'https://23mgutkpv8.execute-api.us-east-1.amazonaws.com/temp';      // IBS production
  // public apiUrl = 'https://ssg5mxumu2.execute-api.us-east-1.amazonaws.com/develop';   // IBS develop
  // public apiUrl = 'http://localhost:3000/develop';   // LOCAL develop
  public apiUrl = 'https://7kcq1359hh.execute-api.us-east-1.amazonaws.com/develop';   // Connectivity develop

  constructor(private http: HttpClient, private dataService: DataService, private storageService: StorageService, private notificationService: NotificationService) { }

  public requestResetPasswordCode(email) {
    let url = `${this.apiUrl}/email/generate-reset-password-code`;
    return this.http.post(url, { email }).pipe(catchError(error => {
      this.notificationService.showError(error.error.message || 'There was a problem with your request. Please try again.');
      return throwError(error);
    }));
  }

  getProvidersImageUrl(file_name, file_extension) {
    return `https://connectivity-finder-logos.s3.amazonaws.com/providers/${file_name}.${file_extension}`;
  }

  async getBase64ImageFromUrl(imageUrl) {
    var res = await fetch(imageUrl);
    var blob = await res.blob();
    return new Promise((resolve, reject) => {
      var reader  = new FileReader();
      reader.addEventListener("load", function () {
          resolve(reader.result);
      }, false);
      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    })
  }

  
  public resetPassword(code, password) {
    let url = `${this.apiUrl}/user/reset-password`;
    return this.http.post(url, { code, password }).pipe(catchError(error => {
      this.notificationService.showError(error.error.message || 'There was a problem with your request. Please try again.');
      return throwError(error);
    }));
  }

  public login(user) {
    let url = `${this.apiUrl}/user/login`;
    return this.http.post(url, user, { headers: { 'Content-Type': 'application/json' } });
  }


  public signup(user) {
    let url = `${this.apiUrl}/user`;
    return this.http.post(url, user);
  }

  public multiSiteSearch() {
    let url = `${this.apiUrl}/multi-site-search`;
    return this.http.get(url);
  }

  public allMultiSiteSearch() {
    let url = `${this.apiUrl}/multi-site-search/admin`;
    return this.http.get(url);
  }

  public sendEmail(sender, subject, message, message_type, info) {
    let url = `${this.apiUrl}/email/survey`;
    let body = { sender, subject, message, message_type, info };
    return this.http.post(url, body);
  }

  public getAllUsers() {
    let url = `${this.apiUrl}/users`;
    return this.http.get(url);
  }

  public updateUserData(user) {
    let url = `${this.apiUrl}/user`;
    return this.http.put(url, user);
  }

  public getAllRoles(): Observable<any> {
    let url = `${this.apiUrl}/role`;
    return this.http.get(url);
  }

  public getAllOrganizations(): Observable<any> {
    let url = `${this.apiUrl}/organization`;
    return this.http.get(url);
  }

  public createOrganization(org) {
    let url = `${this.apiUrl}/organization`;
    return this.http.post(url, org);
  }

  public updateOrganizationData(org) {
    let url = `${this.apiUrl}/organization`;
    return this.http.put(url, org);
  }

  public updateOrganizationStatus(id, activationFlag) {
    let url = `${this.apiUrl}/organization/status`;
    let body = { id, activationFlag };
    return this.http.put(url, body);
  }

  public organisationImageUpload(imageForm) {
    return this.http.post(`${this.apiUrl}/organisation/image_upload`, imageForm);
  }

  public getConfigDetails() {
    let url = `${this.apiUrl}/config`;
    return this.http.get(url);
  }

  public updateConfigDetails(config) {
    let url = `${this.apiUrl}/config`;
    return this.http.put(url, config);
  }

  public dailySummary() {
    let url = `${this.apiUrl}/search-summary/user`;
    return this.http.get(url);
  }

  public searchHistory(username, address, from_date, to_date, fiber, coax, provider, exportAll) {
    let url = `${this.apiUrl}/search-history/admin`;
    let params = new HttpParams();
    params = username ? params.append('username', username) : params;
    params = address ? params.append('address', address) : params;
    params = from_date ? params.append('from_date', from_date) : params;
    params = to_date ? params.append('to_date', to_date) : params;
    params = fiber ? params.append('fiber', fiber) : params;
    params = coax ? params.append('coax', coax) : params;
    params = provider ? params.append('provider', provider) : params;
    params = exportAll ? params.append('exportAll', exportAll) : params;
    return this.http.get<any>(url, { params: params, headers: { "Content-Type": "application/json" } });

  }

  public getAllOrganizationTypes(): Observable<any> {
    let url = `${this.apiUrl}/organization/type`;
    return this.http.get(url);
  }
  public createOrganizationType(org) {
    let url = `${this.apiUrl}/organization/type`;
    return this.http.post(url, org);
  }

  public managePasw(id, oldPassword, newPassw) {
    let url = `${this.apiUrl}/user/manage-pasw`;

    let body = {
      'user_id': id,
      'old_pasw': oldPassword,
      'new_pasw': newPassw
    };
    return this.http.post(url, body);
  }

  public updateOrganizationType(org) {
    let url = `${this.apiUrl}/organization/type`;
    return this.http.put(url, org);
  }


  /**
   * Get nearby connection points based on the external APIs and the lit buildings from the database.
   * @param coordinates
   * @param zip
   * @param address
   * @param city
   * @param state
   * @param numberOfRetries
   * @param googleAddress
   */
  public getNearbyConnectionPoints(coordinates, zip, address, city, state, numberOfRetries, googleAddress) {
    let url = this.apiUrl + '/lit-buildings';
    let params = new HttpParams();
    params = params.append('lat', coordinates.lat);
    params = params.append('lng', coordinates.lng);
    params = params.append('zip', zip);
    params = params.append('address', address);
    params = params.append('city', city);
    params = params.append('state', state);
    return this.http.get(url, { params: params, headers: { 'Content-Type': 'application/json' } }).pipe(
      retry(numberOfRetries),
      catchError((err) => {
        console.log('url', url);
        console.log('err partial', err);
        return this.getPartialConnectionPoints(coordinates, zip, address, city, state, googleAddress);
      })
    );
  }


  /**
   * Get connection points based only on the lit buildings from the database.
   * @param coordinates
   * @param zip
   * @param address
   * @param city
   * @param state
   * @param googleAddress
   */
  public getPartialConnectionPoints(coordinates, zip, address, city, state, googleAddress) {
    let url = this.apiUrl + '/lit-buildings/partial';
    let params = new HttpParams();
    params = params.append('lat', coordinates.lat);
    params = params.append('lng', coordinates.lng);
    params = params.append('zip', zip);
    params = params.append('address', address);
    params = params.append('city', city);
    params = params.append('state', state);
    return this.http.get(url, { params: params, headers: { 'Content-Type': 'application/json' } }).pipe(
      catchError(error => {
        // Emit that a modal needs to pop up.
        this.dataService.setGoogleAddress(googleAddress);
        this.storageService.setObject('googleAddress', googleAddress);
        this.dataService.emitShowTimeoutModal();
        return throwError(error);
      })
    );
  }

  public getPresignedURL(fileName, method) {
    let url = `${this.apiUrl}/presign`;
    let params = new HttpParams()
      .set('filename', fileName).set('method', method);
    return this.http.get(url, { params: params });
  }

  public presignedUpload(presignedURL, file) {
    console.log('presignedURL', presignedURL);
    console.log('file', file);
    return this.http.put(presignedURL, file);
  }

  public organizationLogoUrl(organizationId, fileName, method) {
    let url = `${this.apiUrl}/organizationLogo`;
    let params = new HttpParams()
      .set('organizationId', organizationId).set('filename', fileName).set('method', method);
    return this.http.get(url, { params: params });
  }

  public getServicesFromFile() {
    return this.http.get("./../assets/json/CoaxAndFiber.json");
  }

  public getServices(provider, comcastDivision, location) {
    let url = `${this.apiUrl}/services`;
    let params = new HttpParams().set('provider', provider).set('comcast_division', comcastDivision).set('location', location);
    return this.http.get(url, { params });
  }

  public getGoogleNearbyPlaces(lat, lon, radius, type, token) {
    let url = `${this.apiUrl}/nearby-places`;
    let params = new HttpParams()
      .set('lat', lat)
      .set('lon', lon)
      .set('radius', radius)
      .set('type', type);
    if (token) {
      params = params.append('pagetoken', token);
    }
    return this.http.get(url, { params: params });
  }

  public getComcastPromotions(zip) {
    let url = `${this.apiUrl}/promotions/comcast`;
    return this.http.get(url, { params: { zip: zip } });
  }

  public savePricingQuote(user_id, customer_name, file_name, email_addresses, provider, quote_name, selected_services, pricing_info) {
    let url = `${this.apiUrl}/quote`;
    return this.http.post(url, { user_id, customer_name, file_name, email_addresses, provider, quote_name, selected_services, pricing_info });
  }

  public getProviders() {
    let url = `${this.apiUrl}/provider`;
    return this.http.get(url);
  }

  public addProvider(provider) {
    let url = `${this.apiUrl}/provider`;
    return this.http.post(url, provider).pipe(catchError(error => {
      this.notificationService.showError(error.error.message || 'There was a problem with your request. Please try again.');
      return throwError(error);
    }));
  }

  public updateProvider(provider) {
    let url = `${this.apiUrl}/provider`;
    return this.http.put(url, provider);
  }

  public getCoxMarket(zip) {
    let url = `${this.apiUrl}/market/cox`;
    return this.http.get(url, { params: { zip } });
  }

  public getAllQuotes() {
    let url = `${this.apiUrl}/quote/admin`;
    return this.http.get(url);
  }

  public getQuotesByUser(userId) {
    let url = `${this.apiUrl}/quote/user`;
    return this.http.get(url, { params: { user_id: userId } });
  }

  public sendContractToDocusign(file_name, email, contact_name) {
    let url = `${this.apiUrl}/docusign`;
    const userData = this.storageService.getObject('userData');
    let sender_email = userData.email;
    let sender_name = `${userData.first_name} ${userData.last_name}`;
    return this.http.post(url, { file_name, email, contact_name, sender_email, sender_name });
  }

  public sendFiberContractToDocusign(file_name, email, contact_name) {
    let url = `${this.apiUrl}/docusign-fiber`;
    const userData = this.storageService.getObject('userData');
    let sender_email = userData.email;
    let sender_name = `${userData.first_name} ${userData.last_name}`;
    return this.http.post(url, { file_name, email, contact_name, sender_email, sender_name });
  }

  public sendAccFiberContractToDocusign(file_name, email, contact_name) {
    let url = `${this.apiUrl}/docusign-acc`;
    const userData = this.storageService.getObject('userData');
    let sender_email = userData.email;
    let sender_name = `${userData.first_name} ${userData.last_name}`;
    return this.http.post(url, { file_name, email, contact_name, sender_email, sender_name });
  }

  public addContractToQuote(quote_id, contract) {
    let url = `${this.apiUrl}/quote/add-contract`;
    return this.http.post(url, { quote_id, contract });
  }

  getComcastCredentials() {
    let url = `${this.apiUrl}/comcast-api/user`;
    return this.http.get(url);
  }

  updateComcastPassword(username, password, user_id) {
    const url = `${this.apiUrl}/comcast-api/user/update`;
    const data = {
      username: username,
      password: password,
      user_id: user_id
    }
    return this.http.post(url, data);
  }

  public getComcastPackages(zip) {
    let url = `${this.apiUrl}/packages/comcast`;
    return this.http.get<any>(url, { params: { zip: zip } });
  }
  public getPackageServices(provider, comcastDivision) {
    let url = `${this.apiUrl}/package/services`;
    let params = new HttpParams().set('provider', provider).set('comcast_division', comcastDivision);
    return this.http.get<any>(url, { params });
  }

  public getFiberServices(service_name, term, comcast_division) {
    let url = `${this.apiUrl}/fiber-services`;
    let params = new HttpParams().set('service_name', service_name).set('term', term).set('comcast_division', comcast_division);
    return this.http.get<any>(url, { params });
  }

  public addFiberDisclaimerQuote(quote_id, disclaimer_flag) {
    let url = `${this.apiUrl}/quote/add-fiber-disclaimer`;
    return this.http.post(url, { quote_id, disclaimer_flag });
  }

  public sendSpectrumFiberContractToAdobesign(file_name, email, contact_name) {
    let url = `${this.apiUrl}/adobesign-spectrum-fiber`;
    const userData = this.storageService.getObject('userData');
    let sender_email = userData.email;
    let sender_name = `${userData.first_name} ${userData.last_name}`;
    return this.http.post(url, { file_name, email, contact_name, sender_email, sender_name });
  }

  public externalExport(data) {
    const url = `${this.apiUrl}/export-from-db`;
    const payload = {
      resource: data.resource,
      provider: data.provider,
      fiber: data.fiber,
      packages: data.packages
    }
    return this.http.post(url, payload);
  }

  public getCustomMessages() {
    const url = `${this.apiUrl}/custom-messages`;
    return this.http.get<any>(url);
  }

  public updateCustomMessages(data) {
    const url = `${this.apiUrl}/custom-messages`;
    return this.http.post(url, data);
  }

  public statisticsData(fromDate, toDate, organization) {
    const url = `${this.apiUrl}/report`;
    const body: any = {
      from: fromDate,
      to: toDate
    }
    if (organization) {
      body.organization = organization;
    }
    return this.http.post(url, body);
  }
  public getCoxLegalEntities() {
    const url = `${this.apiUrl}/cox/legal-entities`;
    return this.http.get(url);
  }

  public getImportStatuses() {
    let url = `${this.apiUrl}/schema-imports`;
    return this.http.get(url);
  }

  public sendContractToIBSDocusign(file_name, email, contact_name) {
    let url = `${this.apiUrl}/ibs-docusign`;
    const userData = this.storageService.getObject('userData');
    let sender_email = userData.email;
    let sender_name = `${userData.first_name} ${userData.last_name}`;
    return this.http.post(url, { file_name, email, contact_name, sender_email, sender_name });
  }
}