//Modules
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

const appRoutes: Routes = [
    {path:'ibs', loadChildren:'./cableco/cableco.module#CablecoModule' },
    {path:'', loadChildren:'./users/users.module#UsersModule' },
    { path: '**', redirectTo: '/', pathMatch: 'full' }
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes),
    ],
    exports: [
        RouterModule
    ]
})

export class AppRoutingModule { }
