import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationEnd, ResolveEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/shared/services/api.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  @ViewChild('timeout_modal') timeoutModal;

  public template = '<img src="../../assets/images/ibs-logo.png" class="squigle animated pulse"/>'
  subscription: Subscription;
  custom_message_subscription: Subscription;
  
  private modalMessage = '';
  private modalTitle = '';

  constructor(private spinnerService: Ng4LoadingSpinnerService, private router: Router, private apiService: ApiService, private modalService: NgbModal) {}

  ngOnInit() {
    this.subscription = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => window.scrollTo(0,0));
    this.custom_message_subscription = this.router.events.pipe(filter(event => event instanceof ResolveEnd)).subscribe(route => {
      let activeRoute = route['url'];
      if(activeRoute.includes('ibs')) {
        this.apiService.getCustomMessages().subscribe(response => {
          response.forEach(msg => {
            if (msg.enabled) {
              let currentPath = route['url'];
              let paths = msg.paths;
              let status = msg.status;
              this.modalMessage = msg.message ? msg.message : '';
              if (paths && paths.replace('{', '').replace('}', '').split(',').includes(currentPath)) {
                if (status === 'info') {
                  this.modalTitle = 'INFO';
                } else if (status === 'error') {
                  this.modalTitle = 'ERROR'
                }
                this.showTimeoutModal();
              }
            }
          });
        });
      }
    });
  }

  // tslint:disable-next-line: use-life-cycle-interface
  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.custom_message_subscription.unsubscribe();
  }

  showTimeoutModal() {
    let options: NgbModalOptions = {
      ariaLabelledBy: 'modal-basic-title'
    };
    this.modalService.open(this.timeoutModal, options);
  }

  close() {
    this.modalService.dismissAll();
  }

}