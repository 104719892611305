import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NotificationService } from './services/notification.service';
import { StorageService } from './services/storage.service';
import { ApiService } from './services/api.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { WrapperSpinnerService } from './services/wrapper-spinner.service';


@Injectable()
export class Interceptor implements HttpInterceptor {
    private userData;
    private apiUrl;

    constructor(private notificationService: NotificationService, private spinnerService: Ng4LoadingSpinnerService, private storageService: StorageService,
        private router: Router,private apiService: ApiService, private wrapperSpinnerService: WrapperSpinnerService) {
        this.apiUrl = this.apiService.apiUrl;
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.userData = this.storageService.getObject('userData');
        let token;
        if (this.userData) {
            token = this.userData.token;
        }

        let cloneRequest: HttpRequest<any>;
        const showSpinner = request.url.includes(this.apiUrl);

        if (showSpinner) {
            this.wrapperSpinnerService.show();
            cloneRequest = request.clone({
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                })
            });
        } else {
            cloneRequest = request.clone();
        }

        return next.handle(cloneRequest).pipe(
            finalize(() => {
                if (showSpinner) {
                    this.wrapperSpinnerService.hide();
                }

            }),
            catchError((error: any) => {
                if (error.status == 403) {

                    let temp = localStorage.getItem("agreement");
                    localStorage.clear();
                    localStorage.setItem("agreement", temp);


                    if (window['$_Tawk']) {
                        window['$_Tawk'].hideWidget();
                    }
                    this.router.navigate(['/login']);
                    window.location.reload();
                }
                let errorMessage = 'An unexpected error occured';
                if (error.error && error.error.message) {
                    errorMessage = error.error.message;
                }
                // TODO: Error notifications are currently disabled.
                if (request.url.includes('login')) {
                    this.notificationService.showError(errorMessage);
                }
                return throwError(error)
            }),
            catchError((error: HttpErrorResponse) => {
                if (error.status == 403) {
                   
                    let temp = localStorage.getItem("agreement");
                    localStorage.clear();
                    localStorage.setItem("agreement", temp);

                    if (window['$_Tawk']) {
                        window['$_Tawk'].hideWidget();
                    }
                    this.router.navigate(['/login']);
                    window.location.reload();
                }
                return throwError(error)
            })
        )
    }
    
}
